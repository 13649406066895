<template>
  <v-app>
    <v-app-bar flat dense app>
      Anderson Software LLC
    </v-app-bar>
    <v-main>
      <nuxt />
    </v-main>
    <v-footer fixed app>
      <span>&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>
